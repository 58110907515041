import { defineStore } from "pinia";
import client from "@/plugins/axios";
import { useAuthStore } from "@/stores/AuthStore";

export const useCryptoStore = defineStore("useCryptoStore", {
  state() {
    return {
      isin: undefined,
      cryptosList: [],
      loading: false,
    };
  },
  // getters: {
  //   isLoading: (state) => state.loading,
  // },
  actions: {
    getCoin(ticker) {
      return this.data.filter((crypto) => crypto.ticker === ticker);
    },
    async getCryptos(isinArray) {
      const authStore = useAuthStore();
      const data = {
        isin_tkr: isinArray,
        config: authStore.config,
      };
      authStore.logAction('search_cryptos');
      try {
        return client.post("/cryptos", data).then((response) => {
          this.cryptosList = response.data.data;
          return response.data.data;
        });
      } catch (err) {
        this.error = err;
      }

      // finally {
      //     this.loading = false;
      // }
    },
  },
});
