import { defineStore } from "pinia";
import { useOperandStore } from "@/stores/OperandStore";
import { useAuthStore } from "@/stores/AuthStore";
import client from "@/plugins/axios";

const authStore = useAuthStore();
const operandStore = useOperandStore();

const defaultWeight = { weight: null, operand: null, percentage: null };
const defaultComposition = {
  composition: null,
  operand: null,
  percentage: null,
};

const limitsToOperand = (limit1, limit2) => {
  if (limit1 === limit2) {
    return 3;
  }
  if (limit1 === 0 && limit2 < 1) {
    return 2;
  }
  if (limit1 === 1 && limit2 < 1) {
    return 1;
  }
  return 0;
};

export const usePortfolioLimitStore = defineStore("usePortfolioLimitStore", {
  state() {
    return {
      weights: [],
      compositions: [],
      categories: [],
      defaultCategories: [],
      max_weight: null,
      apply_risk: null,
      delete_weight_zero: true,
      sliders: {
        NA: 2, // north america
        EM: 2, // emerging
        DE: 2, // developed europe
        // PB: 1, // Price / Benefit
        CD: 1, // concentrated or diversified
      },
      only_my_bank: false,
      invest_amount: 0,
      listOfCategories: [],
      listOfComparers: operandStore.operands,
      loading: false,
      error: false,
    };
  },
  actions: {
    addWeight() {
      this.weights.push({ ...defaultWeight });
    },
    addComposiciones() {
      this.compositions.push({ ...defaultComposition });
    },
    removeWeight(index) {
      this.weights.splice(index, 1);
      this.categories = this.categories.filter(item => this.weights.find(weight => weight.weight === item));
    },
    removeComposiciones(index) {
      this.compositions.splice(index, 1);
    },
    removeCategory(value) {
      this.categories = this.categories.filter(item => item !== value);
      this.weights = this.weights.filter(item => item.weight !== value);
    },
    async getDefaultCategories() {
      this.categories = [];
      let configCategories = await authStore.config.searches;
      Object.entries(configCategories).filter(([key, value]) => {
        if (value.default === true) {
          this.categories.push(key);
          this.defaultCategories.push(key);
        }
      });
      this.resetAllWeights();
      this.setAllCompositions();
      this.apply_risk = null;
    },
    async getCategoriesFilter() {
      try {
        return client.get("/categories").then((response) => {
          this.listOfCategories = response.data;
        });
      } catch (err) {
        this.error = err;
      }
    },
    async generateAdvancedPortfolio() {
      try {
        const risks = Object.values(authStore.config["risk_levels"]);
        let default_risk = 0;
        if (risks.length) {
          default_risk = risks[Math.ceil(risks.length / 2) - 1];
        }
        const default_weight = authStore.config["max_weight"];
        this.loading = true;
        const data = {
          categories: this.categories,
          max_weight:
            this.max_weight !== null ? this.max_weight : default_weight,
          apply_risk: this.apply_risk || default_risk,
          delete_weight_zero: true,
          only_my_bank: this.only_my_bank,
          invest_amount: this.invest_amount,
          compositions: this.compositions.filter(
            (e) =>
              e.composition !== null ||
              e.operand !== null ||
              e.percentage !== null
          ),
          weights: this.weights.filter(
            (e) =>
              e.weight !== null || e.operand !== null || e.percentage !== null
          ),
        };
        return client
          .post("/portfolio/advanced", data)
          .then((response) => {
            authStore.logAction('advanced_portfolio_generation');
            return response.data.data;
          })
          .finally(() => {
            this.loading = false;
          });
      } catch (err) {
        this.error = err;
      }
    },
    async generatePortfolio() {
      try {
        this.loading = true;
        const data = {
          ...this.sliders,
          only_my_bank: this.only_my_bank,
          invest_amount: this.invest_amount,
        };
        return client
          .post("/portfolio", data)
          .then((response) => {
            authStore.logAction('portfolio_generation');
            return response.data.data;
          })
          .finally(() => {
            this.loading = false;
          });
      } catch (err) {
        this.error = err;
        return false;
      }
    },
    resetFilters() {
      this.resetAllWeights();
      this.setAllCompositions();
    },
    resetAllWeights() {
      this.weights = [];
      this.categories = [];
      const configCategories = authStore.config.searches;
      Object.entries(configCategories).map(([cat, value]) => {
        if (value.default === true) {
          this.weights.push({
            weight: cat,
            operand: limitsToOperand(
              value.limits[0],
              value.limits[1]
            ),
            percentage: value.limits[1],
          });
          this.categories.push(cat);
        }
      });
    },
    updateAllWeights() {
      const currentWeights = this.weights;
      this.weights = [];
      const configCategories = authStore.config.searches;
      Object.entries(configCategories).map(([cat, value]) => {
        if (this.categories.includes(cat)) {
          const newWeight = {
            weight: cat,
            operand: limitsToOperand(
              value.limits[0],
              value.limits[1]
            ),
            percentage: value.limits[1],
          };
          if (currentWeights?.find(e => e?.weight === cat)) {
            this.weights.push(currentWeights?.find(e => e?.weight === cat));
          } else {
            this.weights.push(newWeight);
          }
        }
      });
    },
    setAllCompositions() {
      this.compositions = [];
      const compositionColumns =
        authStore.config.funds_filtrable_columns.compositions;
      const topLevelCompositionKeys = Object.keys(compositionColumns);
      topLevelCompositionKeys.map((key) => {
        const firstLevel = compositionColumns[key];
        Object.keys(firstLevel).forEach((subkey) => {
          const comp = firstLevel[subkey];
          if (comp["limitar_composiciones"]) {
            if (
              !comp["apply"] ||
              this.weights.some((w) => w.weight.startsWith(comp["apply"]))
            ) {
              const operand = limitsToOperand(comp.limites[0], comp.limites[1]);
              if (operand !== 0) {
                this.compositions.push({
                  composition: subkey,
                  operand,
                  percentage: comp.limites[1],
                });
              }
            }
          }
        });
      });
    },
  },
  getters: {
    isPesosEmpty: (state) => state.weights.length <= 1,
    isComposicionesEmpty: (state) => state.compositions.length <= 1,
    areThereWeights(state) {
      const activeWeights = state.weights.filter((weight) => !!weight.weight);
      return activeWeights.length ? true : false;
    },
    areThereCompositions(state) {
      const activeCompositions = state.compositions.filter(
        (composition) => !!composition.composition
      );
      return activeCompositions.length ? true : false;
    },
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: "weights",
        storage: localStorage,
      },
      {
        key: "compositions",
        storage: localStorage,
      },
    ],
  },
});
