import { defineStore } from "pinia";
import client from "@/plugins/axios";
import { useAuthStore } from "@/stores/AuthStore";
export const usePensionsStore = defineStore("usePensionsStore", {
  state() {
    return {
      data: { region_id: undefined, pension_type: undefined },
      pensionSelectedList: [],
      categories: [],
      regions: [],
      types: [],
      isin: [],
      loadingRegions: false,
      loadingTypes: false,
      loadingPensions: false,
    };
  },
  getters: {
    pensions: (state) => state.data.pensions,
  },
  actions: {
    async getRegions() {
      try {
        this.loadingRegions = true;
        return client
          .get("/countries", { params: { type: "pensions" } })
          .then((response) => {
            this.regions = response.data;
            return response.data;
          })
          .finally(() => (this.loadingRegions = false));
      } catch (err) {
        return (this.error = err);
      }
    },
    async getPensionTypes() {
      try {
        this.loadingTypes = true;
        return client
          .get("/pensions/types")
          .then((response) => {
            this.types = response.data.data;
            return response.data;
          })
          .finally(() => (this.loadingTypes = false));
      } catch (err) {
        return (this.error = err);
      }
    },
    async searchPensions() {
      try {
        this.loadingPensions = true;
        return client
          .post("/pensions/search", {
            region_id: this.data.region_id,
            pension_type: this.data.pension_type,
          })
          .then((response) => {
            this.pensionSelectedList = response.data.data;
            return response.data.data;
          })
          .finally(() => (this.loadingPensions = false));
      } catch (err) {
        return (this.error = err);
      }
    },
    async getPensionsByTkr(isinArr) {
        const authStore = useAuthStore();
        try {
          return client
            .post("/pensions/searchbytkr", {
              isin: isinArr,
              config: authStore.config,
            })
            .then((response) => {
              return response.data.data;
            });
        } catch (err) {
          return (this.error = err);
        }
      },
    },
});