import { defineStore } from "pinia";
// import axios from "axios"
import client from "@/plugins/axios";

export const useOperandStore = defineStore("useOperandStore", {
  state: () => {
    return {
      operands: [
        {
          name: "no_limits",
          value: 0,
        },
        {
          name: "greater_equal_than",
          value: 1,
        },
        {
          name: "less_equal_than",
          value: 2,
        },
        {
          name: "equal_to",
          value: 3,
        },
      ],
      multiplos: [
        {
          name: "million",
        },
        {
          name: "thousand_million",
        },
        {
          name: "billion",
        },
      ],
      genericFilters: [
        { label: "Cap(M)", field: "cap" },
        { label: "P/ER(%)", field: "per" },
        { label: "Yield(%)", field: "yield" },
      ],
      countries: [],
      industries: [],
      sectors: [],
      regions: [],
      currencies: [],
      etfCurrencies: [],
      morningStart: ["1", "2", "3", "4", "5"],
      yesNo: ["true", "false"],
      sizes: [
        { value: "Small", label: "Small" },
        { value: "Mid/Small", label: "Mid/Small" },
        { value: "Mid", label: "Mid" },
        { value: "Flex", label: "Flex" },
        { value: "Big", label: "Big" },
      ],
      factors: [
        { value: "Growth", label: "Growth" },
        { value: "Blend", label: "Blend" },
        { value: "Value", label: "Value" },
        { value: "High dividend", label: "High dividend" },
      ],
      altType: [
        { value: "Alt conservative", label: "Alt conservative" },
        { value: "Alt moderate", label: "Alt moderate" },
        { value: "Alt dynamic", label: "Alt dynamic" },
        { value: "Alt aggressive", label: "Alt aggressive" },
        { value: "Inverse", label: "Inverse" },
        { value: "Fixed income inverse", label: "Fixed income inverse" },
        { value: "Guaranteed", label: "Guaranteed" },
      ],
      mixTypes: [
        { value: "Mix moderate", label: "Mix moderate" },
        { value: "Mixed conservative", label: "Mixed conservative" },
        { value: "Mix dynamic", label: "Mix dynamic" },
        { value: "Mixed aggressive", label: "Mixed aggressive" },
      ],
      termCat: [
        { value: "Ultra short", label: "Ultra short" },
        { value: "Short", label: "Short" },
        { value: "Mid", label: "Mid" },
        { value: "Flexible", label: "Flexible" },
        { value: "Long", label: "Long" },
      ],
      rentaRF: [
        { value: "Government", label: "Government" },
        { value: "High yield", label: "High yield" },
        { value: "Convertible", label: "Convertible" },
        { value: "Corporate", label: "Corporate" },
        { value: "Inflation-Linked", label: "Inflation-Linked" },
        { value: "Subordinated", label: "Subordinated" },
        { value: "Diversified", label: "Diversified" },
      ],
      // fees: [],
      // min_ini_purchase: [],
      // fund_size: [],
      // share_class_size: [],
      // distribution: ["true", "false"],
      // years_since_launch: [],
      risks: [],
      // ucits: ["true", "false"],
      legal_structures: [],
    };
  },
  actions: {
    async getCountries(type) {
      try {
        await client
          .get("/countries", { params: { type } })
          .then((response) => {
            this.countries = response.data;
            return response.data.data;
          });
      } catch (err) {
        return (this.error = err);
      }
    },

    async getIndustries(type) {
      try {
        await client
          .get("/industries", { params: { type } })
          .then((response) => {
            this.industries = response.data.data;
            return response.data.data;
          });
      } catch (err) {
        return (this.error = err);
      }
    },

    async getSectors(type) {
      try {
        await client.get("/sectors", { params: { type } }).then((response) => {
          this.sectors = response.data.data;
          return response.data.data;
        });
      } catch (err) {
        return (this.error = err);
      }
    },

    async getCurrencies() {
      try {
        return client.get("/currencies").then((response) => {
          this.currencies = response.data.data;
          return response.data;
        });
      } catch (err) {
        return (this.error = err);
      }
    },

    async getEtfCurrencies() {
      try {
        return client.get("/etf-currencies").then((response) => {
          this.etfCurrencies = response.data.data;
          return response.data;
        });
      } catch (err) {
        return (this.error = err);
      }
    },

    async getRegions(type) {
      try {
        return client.get("/regions", { params: { type } }).then((response) => {
          this.regions = response.data.data;
          return response.data;
        });
      } catch (err) {
        return (this.error = err);
      }
    },

    async getRisks() {
      try {
        return client.get("/risks").then((response) => {
          this.risks = response.data.data;
          return response.data;
        });
      } catch (err) {
        return (this.error = err);
      }
    },

    async getLegalStructures() {
      try {
        return client.get("/legal-structures").then((response) => {
          this.legal_structures = response.data.data;
          return response.data;
        });
      } catch (err) {
        return (this.error = err);
      }
    },
  },
});
