import { defineStore } from "pinia";
import searchFilters from "@/demoData/searchFilters.json";
import client from "@/plugins/axios";
import { useAuthStore } from "@/stores/AuthStore";

export const useEtfsStore = defineStore("useEtfsStore", {
  state: () => {
    return {
      data: {
        isin: [],
        currency: undefined,
        etfsTypes: undefined,
        sector_id: undefined,
        factor: undefined,
        size: undefined,
        patrimony: undefined,
        category_rent: undefined,
        mix_type: undefined,
        altType: undefined,
        fund_size: undefined,
        inverse: undefined,
        leverage: undefined
      },
      selector: [],
      etfs: [],
      searchFilters: searchFilters,
      etfsTypes: [],
      factors: ["High dividend", "Value", "Blend", "Growth"],
      dynamic: [],
      dynamicGraph: [],
      conversion_ratios: [],
      isLoading: false,
      error: null,
      selectCriteriaList: [
        { key: 0, value: "add_all" },
        { key: 1, value: "change_criteria" },
      ],
    };
  },
  getters: {
    getDynamicList: (state) => {
      return state.dynamic;
    },
    getDynamicGraph: (state) => {
      return state.dynamicGraph;
    },
    mapConfigToModel: () => {
      return (config) => {
        switch (config) {
          case "country":
            return "country_id";
          case "sector":
            return "sector_id";
          case "fi_types":
            return "fi_type";
          case "fi_type":
            return "fi_type";
          case "alternative_type":
            return "altType";
          default:
            return config;
        }
      };
    },
  },
  actions: {
    getEtfDataByKey(key) {
      return this.etfs.filter((f) => f.key === key).first();
    },
    getEtfData(key) {
      return this.etfs.filter((f) => f.id === key).first();
    },
    async getEtfsTypes() {
      try {
        return client.get("/etf-types").then((response) => {
          this.etfsTypes = response.data.data;
          return response.data.data;
        });
      } catch (err) {
        return (this.error = err);
      }
    },
    async searchEtfs() {
      this.isLoading = true;
      const authStore = useAuthStore();
      try {
        const data = {
          isin: this.data.isin,
          company: this.data.company,
          config: authStore.config,
        };
        authStore.logAction('search_etfs');
        return client.post("/etfs", data).then((response) => {
          this.etfs = response.data.data;
          return response.data.data;
        });
      } catch (err) {
        this.error = err;
      } finally {
        this.isLoading = false;
      }
    },

    async searchEtfsByIsinTkr(isinTkr) {
      this.isLoading = true;
      const authStore = useAuthStore();
      try {
        const result = [];
        authStore.logAction('search_etfs');
        for (let i = 0; i < isinTkr?.length / 100; i ++) {
          const data = {
            isin: isinTkr.slice(i * 100, (i + 1) * 100),
            config: { fixed_ratio_columns: authStore.config.fixed_ratio_columns },
          };
          await client.post("/etfs", data).then((response) => {
            result.push(...response.data.data);
          });
        }
        this.etfs = result;
        return result;
      } catch (err) {
        this.error = err;
      } finally {
        this.isLoading = false;
      }
    },
    
    async searchEtfsFiltering() {
      this.isLoading = true;
      const authStore = useAuthStore();
      try {
        const data = {
          ...this.data,
        };
        authStore.logAction('search_etfs');
        return client.post("/etfs/filters", data).then((response) => {
          this.etfs = response.data.data;
          return response.data.data;
        });
      } catch (err) {
        this.error = err;
      } finally {
        this.isLoading = false;
      }
    },
    resetData() {
      this.data = {
        isin: [],
        currency: undefined,
        company: undefined,
        etfsTypes: undefined,
        sector_id: undefined,
        factor: undefined,
        size: undefined,
        patrimony: undefined,
        category_rent: undefined,
        mix_type: undefined,
        altType: undefined,
        inverse: undefined,
        leverage: undefined
      };
    },
  },
});