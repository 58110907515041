import { defineStore } from "pinia";
import client from "@/plugins/axios";
import { useAuthStore } from "@/stores/AuthStore";

export const useBenchmarkStore = defineStore("useBenchmarkStore", {
  state() {
    return {
      data: { region_id: undefined, category: undefined },
      benchmarkOptionsList: [],
      benchmarkSelectedList: [],
      categories: [],
      regions: [],
      isin: [],
      loadingRegions: false,
      loadingCategories: false,
      loadingBenchmarks: false,
    };
  },
  getters: {
    benchmarks: (state) => state.data.benchmarks,
  },
  actions: {
    // getBenchmark(ticker) {
    //   this.benchmarks.filter((benchmark) => benchmark.ticker === ticker)
    // }
    async getBenchmarks() {
      const authStore = useAuthStore();
      authStore.logAction('search_benchmarks');
      try {
        return client.get("/benchmarks/options").then((response) => {
          this.benchmarkOptionsList = response.data;
          return response.data;
        });
      } catch (err) {
        return (this.error = err);
      }
    },

    async getCategories() {
      try {
        this.loadingCategories = true;
        return client
          .get("/categories_benchmarks")
          .then((response) => {
            this.categories = response.data;
            return response.data;
          })
          .finally(() => (this.loadingCategories = false));
      } catch (err) {
        return (this.error = err);
      }
    },

    async getRegions() {
      try {
        this.loadingRegions = true;
        return client
          .get("/countries", { params: { type: "benchmarks" } })
          .then((response) => {
            this.regions = response.data;
            return response.data;
          })
          .finally(() => (this.loadingRegions = false));
      } catch (err) {
        return (this.error = err);
      }
    },

    async searchBenchmarks() {
      try {
        this.loadingBenchmarks = true;
        return client
          .post("/benchmarks/search", {
            region_id: this.data.region_id,
            category: this.data.category,
          })
          .then((response) => {
            this.benchmarkSelectedList = response.data.data;
            return response.data.data;
          })
          .finally(() => (this.loadingBenchmarks = false));
      } catch (err) {
        return (this.error = err);
      }
    },

    async getBenchmarksByTkr(isinArr) {
      const authStore = useAuthStore();
      try {
        return client
          .post("/benchmarks/searchbytkr", {
            isin: isinArr,
            config: authStore.config,
          })
          .then((response) => {
            return response.data.data;
          });
      } catch (err) {
        return (this.error = err);
      }
    },
  },
});
