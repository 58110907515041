import MarkerMap from "@/config//graph/graph-symbol-maps";
import { useAuthStore } from "../stores/AuthStore";

const authStore = useAuthStore();

export const getMarker = (symbol) => MarkerMap[symbol] || '';

export const sortTableColumns = (columnDefs, orders) => {
	const pinnedColumns = columnDefs?.filter(item => item.pinned);
	return [ ...pinnedColumns, ...columnDefs?.filter(item => !item.pinned).sort((col1, col2) => {
    const firstIndex = orders?.indexOf(col1?.field || col1?.key);
    const secondIndex = orders?.indexOf(col2?.field || col2?.key);
    if (firstIndex === -1 && secondIndex === -1) {
      return 0;
    } else if (firstIndex === -1) {
      return 1;
    } else if (secondIndex === -1) {
      return -1;
    } else {
      return firstIndex - secondIndex;
    }
  })];
};

export const sortPdfColumns = (reports, tableOrders, reportType) => {
  const config_compositions = reportType === 'asset' ? authStore.pdfConfig?.asset_compositions : authStore.pdfConfig?.portfolio_compositions;
  const config_ratios = authStore.pdfConfig?.asset_tables;
  const changedOrders = tableOrders.map(item => {
    if (item.toLowerCase() === 'asset compositions') {
      return [ ...Object.keys(config_compositions), 'asset compositions' ];
    } else if (item.toLowerCase() === 'ratios') {
      return [ ...Object.keys(config_ratios), 'ratios' ];
    } else return item;
  });
  const orders = [].concat(...changedOrders);
  const filteredData = reports?.filter(item => orders?.includes(item.title));
  const detailedOrderingReports = filteredData?.map(item => {
    if (Object.keys(config_compositions).includes(item?.title)) {
      const configCols = config_compositions[item?.title];
      const newColumns = configCols?.length === 0 ?
        item?.columns :
        reportType === 'asset' ? [ 'name', ...configCols ] : configCols;
      return {
        ...item,
        columns: newColumns?.filter(col => item?.data?.filter(row => !!row[col]).length > 0)
      };
    } if (Object.keys(config_ratios).includes(item?.title)){
      const configCols = config_ratios[item?.title];
      const newColumns = configCols?.length === 0 ?
        item?.columns :
        reportType === 'asset' ? [ 'name', ...configCols ] : configCols;
      return {
        ...item,
        columns: newColumns?.filter(col => item?.data?.filter(row => !!row[col]).length > 0)
      };
    } else return item;
  });
  let finalSortResults = detailedOrderingReports.sort((item1, item2) => {
    let firstIndex = orders.indexOf(item1.title);
    let secondIndex = orders.indexOf(item2.title);
    if (firstIndex === -1 && secondIndex === -1) {
      return 0;
    } else if (firstIndex === -1) {
      return 1;
    } else if (secondIndex === -1) {
      return -1;
    } else {
      return firstIndex - secondIndex;
    }
  });
  return finalSortResults;
};

export const getGraphHeight = (config, windowSize) => {
  if (windowSize < 425) {
    return {
      graph_height: config['graph_height_mobile'],
      legend_max_height: config['legend_max_height_mobile']
    }
  } else if (windowSize < 768) {
    return {
      graph_height: config['graph_height_tablet'],
      legend_max_height: config['legend_max_height_tablet']
    }    
  } else {
    return {
      graph_height: config['graph_height_desktop'],
      legend_max_height: config['legend_max_height_desktop']
    }
  }
};

export const getChatPeriods = (config) => {
  const buttons = [];
  config['extension_periods']?.map(period => {
    if (period === 'ytd') {
      buttons.push({
        type: 'ytd',
        transKey: 'yearToDate',
        dataGrouping: {
          forced: true,
          units: [['day', [1]]]
        }
      });
    } else if (period === 'all') {
      buttons.push({
        type: 'all',
        transKey: 'all'
      });
    } else {
      const count = period.slice(0, 1);
      const type = period.slice(1);
      switch (type) {
        case 'm':
          buttons.push({
            type: 'month',
            count,
            transKey: period
          });
          break ;
        case 'y':
          buttons.push({
            type: 'year',
            count,
            transKey: period
          });
          break ;
        case 'd':
          buttons.push({
            type: 'days',
            count,
            transKey: period
          });
          break ;
        default: break ;
      }
    }
  });
  return buttons;
};

export const customNumberFormatter = (formatter, value, format, decimalQty, locale) => {
  const formattedValue = formatter(value, format, {
    minimumFractionDigits: decimalQty,
  });
  if (format === 'currency' && locale === 'en') {
    return `${formattedValue.slice(1)} ${formattedValue.charAt(0)}`;
  }
  return formattedValue;
};

export const getTitleFromPath = (path) => {
  if (path.startsWith('/portfolio-overview')) {
    return 'generic.buttons.portfolio_summary';
  }
  switch (path) {
    case '/generate-portfolio':
      return 'generic.titles.generate_portfolio';
    case '/generate-portfolio-advanced':
      return 'generic.titles.generate_portfolio';
    case '/load-portfolio':
      return 'generic.titles.load_portfolio';
    case '/import-portfolio':
      return 'generic.titles.import_portfolio';
    case '/search-select':
      return 'generic.titles.select_search';
    case '/search-funds':
      return 'generic.titles.search_funds';
    case '/search-stocks':
      return 'generic.titles.search_stocks';
    case '/search-cryptos':
      return 'generic.titles.search_cryptos';
    case '/search-benchmarks':
      return 'generic.titles.search_benchmark';
    case '/search-etfs':
      return 'generic.titles.search_etfs';
    case '/search-pensions':
      return 'generic.titles.search_pensions';
    case '/basket-assets':
      return 'generic.buttons.asset_basket';
    case '/portfolio-control-panel':
      return 'generic.titles.portfolio_control_panel';
    default: 
      return '';
  }
};