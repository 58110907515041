import { defineStore } from "pinia";
import client from "@/plugins/axios";
import { useAuthStore } from "@/stores/AuthStore";

const defaultExtraFiltersValues = {
  operand: undefined,
  value: undefined,
  units: undefined,
};

export const useStocksStore = defineStore("useStocksStore", {
  state: () => {
    return {
      data: {
        selected_top: undefined,
        selected_country: [],
        selected_industry: [],
        selected_sector: [],
        extraFiltersValues: {},
        isin: [],
      },
      stockList: [],
    };
  },
  actions: {
    async getStocks() {
      const authStore = useAuthStore();
      try {
        const params = {
          isin: this.data.isin,
          config: authStore.config,
        };
        return client.post("/stocks", params).then((response) => {
          this.stockList = response.data.data;
          return response.data.data;
        });
      } catch (err) {
        return (this.error = err);
      }
    },
    async searchStocksByIsinTkr(ids) {
      const authStore = useAuthStore();
      try {
        const params = {
          isin: ids,
          config: authStore.config,
        };
        return client.post("/stocks", params).then((response) => {
          authStore.logAction('search_stocks');
          return response.data.data;
        });
      } catch (err) {
        return (this.error = err);
      }
    },

    async getStocksFiltering() {
      try {
        const params = {
          country_id:
            this.data.selected_country && this.data.selected_country.length
              ? this.data.selected_country
              : [],
          industry_id:
            this.data.selected_industry && this.data.selected_industry.length
              ? this.data.selected_industry
              : [],
          sector_id:
            this.data.selected_sector && this.data.selected_sector.length
              ? this.data.selected_sector
              : [],
          ...this.data.extraFiltersValues,
        };
        console.log(params);
        return client.get("/stocks/filter", { params }).then((response) => {
          this.stockList = response.data.data;
          return response.data.data;
        });
      } catch (err) {
        return (this.error = err);
      }
    },

    async getDefaultSearchs() {
      try {
        return client.get("/search-keywords").then((response) => {
          this.searches = response.data.data;
          return response.data;
        });
      } catch (err) {
        return (this.error = err);
      }
    },
    addFilter(filterName) {
      this.data.extraFiltersValues[filterName] = {
        ...defaultExtraFiltersValues,
      };
    },
    addSelectedCountry(country) {
      this.selected_country = [country];
    },
    addSelectedIndustry(industry) {
      this.selected_country = [industry];
    },
    addSelectedSector(sector) {
      this.selected_country = [sector];
    },
  },

  persist: {
    enabled: false,
    strategies: [],
  },
});
